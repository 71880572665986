<template>
  <div class="news">
    <van-nav-bar :title="$t('news.消息')">
      <template #right>
        <!-- <img src="../../images/bgc/close.png"
             alt=""
             style="width:60px;height:22px;margin-top: 10px;"> -->
      </template>
    </van-nav-bar>
    <div class="content_mes">
      <span>{{mesCount}}条未读消息</span>
      <span v-if="mesCount > 0" @click="readAll">
        <i class="iconfont icon-qingchuweidu"></i>{{ $t('news.清除未读') }}
      </span>
    </div>
    <div class="news_content">
      <van-list v-model="loading"
                :finished="finished"
                finished-:text="$t('news.没有更多了')"
                @load="onLoad">
        <!-- <van-cell v-for="item in ourtableData"
                :key="item"
                :title="item" /> -->
        <div>
          <div v-for="(item,index) in ourtableData"
               :key="index"
               class="content_list">
            <div>
              <span v-if="item.status == 0"></span>
              <p>{{item.content}}</p>
            </div>
            <p>{{item.createTime ? item.createTime.split(' ')[0] : ''}}</p>
          </div>
        </div>
      </van-list>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      ourtableData: [],
      loading: false,
      finished: false,
      pageNum: 0,
      pageSize: 10,
      total: 0,
      mesCount: 0 //未读条数
    }
  },
  methods: {
    onLoad () {
      var conditions = [
        {
          "field": "type",
          "opt": "=",
          "value": "MSG",
          "assemble": "and"
        },
        {
          "field": "emailTo",
          "opt": "=",
          "value": sessionStorage.getItem('userName'),
          "assemble": "and"
        }
      ]
      var params = {
        'where': conditions,
        'pageNum': this.pageNum,
        'pageSize': this.pageSize,
        'orderBy': 'createTime desc'
      };

      params.where = conditions;

      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/message/list',
        data: params
      }).then(res => {
        if (res.data && res.data.rows) {
          this.total = parseInt(res.data.total);
          this.ourtableData = this.ourtableData.concat(res.data.rows);
          this.loading = false;
          this.pageNum++;
          if (this.ourtableData.length >= res.data.total) {
            this.finished = true;
            this.loading = false;
          }
        }
      });
    },
    getMessageCount () {
      this.$myHttp({
        method: 'GET',
        url: '/microarch/sys/message/countsUnread/' + sessionStorage.getItem('userName'),
        data: {}
      }).then(res => {
        this.mesCount = res.data
      });
    },
    //清除未读
    readAll () {
      this.$myHttp({
        method: 'GET',
        url: '/microarch/sys/message/readAll/' + sessionStorage.getItem('userName'),
        data: {}
      }).then(res => {
        this.pageNum = 0;
        this.ourtableData=[];
        this.getMessageCount();
        this.onLoad();
      });
    }
  },
  created () {
    this.getMessageCount();
  }
}
</script>
<style>
.news .content_mes {
  padding: 9px 15px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: rgba(67, 120, 190, 1);
  position: fixed;
  top: 46px;
  z-index: 9;
  background: rgb(238, 238, 238);
  width: 100%;
}
.news .content_mes i {
  width: 14px;
  height: 14px;
  font-size: 14px;
}
.news .content_mes > span:nth-child(2) {
  padding-left: 15px;
  cursor: pointer;
}
.news .news_content {
  padding: 84px 0px 50px;
}
.news .news_content .van-list > div:nth-child(1) {
  background: #fff;
  padding: 0 10px;
}
.news .news_content .van-list > div .content_list {
  padding: 10px 0;
  border-bottom: 1px solid rgba(238, 238, 238, 1);
  display: flex;
  justify-content: space-between;
}
.news .news_content .van-list > div > div > p {
  margin-left: 30px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  flex-shrink: 0;
}
.news .news_content .van-list > div div div {
  display: flex;
  padding-left: 5px;
}
.news .news_content .van-list > div div div span {
  width: 8px;
  height: 8px;
  display: inline-block;
  background: #4378be;
  margin: 8px 10px 8px 0;
  border-radius: 50%;
  flex-shrink: 0;
}
.news .news_content .van-list > div div div p {
  line-height: 25px;
}
</style>
